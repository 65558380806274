<template>
  <NotificationDetailDialogue
      :announcement-text="notificationAnnouncement"
      :description-text="notificationDescription"
      :startDate="notificationStartDate"
      :endDate="notificationEndDate"
  />
  <div class="flex flex-col">
    <!--    Banner foDesktop-->
    <div class="sticky top-0 flex-item z-50 bg-white">
      <div v-if="hasBanner()"
           v-for="(banner, index) in activeBanners"
           :class="index === 0 ? `bg-banner-color relative z-40 w-full p-2 cursor-pointer hidden lg:flex` : `bg-banner-color relative z-40 w-full p-2  cursor-pointer  mt-0.5 hidden lg:flex`"
           :style="{ height: `${bannerHeight}px`}"
           :key="banner.id">
        <Banner
            :banner-index="index"
            :announcement-id="banner.id"
            :announcement-text="banner.announcement"
            :description-text="banner.announcementDescription"
            :announcement-start-date="banner.startDate"
            :announcement-end-date="banner.endDate"
        />
      </div>
    </div>

    <div class="flex-grow flex-item">
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-40 lg:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                           enter-from="opacity-0"
                           enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
                           leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80"/>
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                             enter-from="-translate-x-full" enter-to="translate-x-0"
                             leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                             leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                 enter-to="opacity-100" leave="ease-in-out duration-300"
                                 leave-from="opacity-100"
                                 leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-color px-6 pb-4">
                  <div class="flex h-16 shrink-0 items-center mt-4">
                    <AppIconAndName/>
                  </div>
                  <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in getSidebarNavigations(getLoggedInUser, (getCompanyDetailsLocal as CompanyDetails), route.path)"
                              :key="item.name">
                            <nuxt-link :to="item.href" @mousedown="sidebarOpen=false"
                                       :class="[item.href==currentPath ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                              <Icon :name="item.icon"
                                    :class="[item.href==currentPath ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']"
                                    aria-hidden="true"/>
                              <span>{{ (item.name) }}</span>
                            </nuxt-link>
                          </li>
                        </ul>
                      </li>
                      <li class="hidden">
                        <div class="text-xs font-semibold leading-6 text-indigo-200">
                          {{ ('Your teams') }}
                        </div>
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                          <li v-for="team in teams" :key="team.name">
                            <a :href="team.href"
                               :class="[team.current ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                              <span
                                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">{{
                                  team.initial
                                }}</span>
                              <span class="truncate">{{ team.name }}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-auto">
                        <a @mousedown="onLogout"
                           class="group cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white">
                          <ArrowRightOnRectangleIcon
                              class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                              aria-hidden="true"/>
                          {{ ('Sign out') }}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- Static sidebar for desktop -->
      <div class="hidden lg:fixed lg:z-40 lg:flex lg:w-72 lg:flex-row bottom-0"
           :style="{ top: `${(numberOfActiveBanners() || 0) * bannerHeight}px` }"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-color px-6 pb-4">
          <div class="flex h-16 items-center">
            <AppIconAndName/>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1" v-if="getLoggedInUser != null">
                  <li v-for="item in getSidebarNavigations(getLoggedInUser, (getCompanyDetailsLocal as CompanyDetails))"
                      :key="item.name">
                    <nuxt-link v-if="!item.children" :to="item.href"
                               :class="[isSelectedMenu(currentPath, item.href) ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium']">
                      <Icon :name="item.icon"
                            :class="[item.href==currentPath ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']"
                            aria-hidden="true"/>
                      {{ (item.name) }}
                    </nuxt-link>
                    <Disclosure :default-open="item.open" as="div" v-else v-slot="{ open }">
                      <DisclosureButton
                          :class="[item.current ? 'bg-gray-50' : 'hover:bg-indigo-700', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700']">
                        <Icon :name="item.icon" class="h-6 w-6 shrink-0 text-indigo-200 hover:text-white"
                              aria-hidden="true"/>
                        <span class="text-indigo-200 hover:text-white text-sm font-medium">{{ item.name }}</span>
                        <Icon :name="item.trailIcon"
                              :class="[open ? 'rotate-90' : '', 'text-indigo-200 hover:text-white ml-auto h-5 w-5 shrink-0']"
                              aria-hidden="true"/>
                      </DisclosureButton>
                      <DisclosurePanel as="ul" class="mt-1 px-2">
                        <li v-for="subItem in item.children" :key="subItem.name">
                          <!-- 44px -->
                          <nuxt-link :to="subItem.href"
                                            :class="[subItem.current ? 'text-white bg-indigo-700' : '', 'text-indigo-200 hover:text-white text-sm font-medium rounded-md hover:bg-indigo-700 mb-2 flex items-center justify-start gap-2 px-4 py-2']">
                            <Icon :name="subItem.icon"
                                  :class="[subItem.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']"
                                  aria-hidden="true"/>
                            {{ subItem.name }}
                          </nuxt-link>
                        </li>
                      </DisclosurePanel>
                    </Disclosure>
                  </li>
                </ul>
              </li>

              <li class="mt-auto">
                <a @mousedown="onLogout"
                   class="group cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white">
                  <ArrowRightOnRectangleIcon
                      class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                      aria-hidden="true"/>
                  {{ ('Sign out') }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="lg:pl-72">
        <div
            class="sticky z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
        >
          <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
            <span class="sr-only">{{ ('Open sidebar') }}</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
          </button>

          <!-- Separator -->
          <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>

          <div class="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6">
            <div class="relative flex flex-1 flex-col">
                        <span class="font-semibold xsm:text-md text-black line-clamp-1 text-xl">{{
                            getTitle()
                          }}</span>
              <div v-if="hasStatistic(currentPath)" class="text-gray-500 text-sm flex flex-row items-center gap-2 ">
                <div class="flex flex-row items-center gap-1">
                  <!--                <Icon class="h-4 w-4 " name="material-symbols:broken-image-outline"/>-->
                  <span class="text-brand-color">{{ getFeedPostCount }}</span>
                  <span>Photos</span>
                </div>
                <span>|</span>
                <div class="flex flex-row gap-1 items-center">
                  <!--                <Icon class="h-4 w-4 " name="material-symbols:favorite-rounded"/>-->
                  <span class="text-brand-color">{{ totalVoteCount }}</span>
                  <span>Votes</span></div>
              </div>
            </div>

            <div class="flex items-center gap-x-4 lg:gap-x-6">

              <NotificationBell/>

              <!--            <LanguageSwitchDropdown light-bg="true"/>-->
              <!-- Separator -->

              <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"/>

              <!-- Profile dropdown -->
              <Menu as="div" class="relative flex-shrink">
                <MenuButton class="-m-1.5 flex items-center ">
                  <span class="sr-only">Open menu</span>
                  <ProfilePicture :key="getImgUrl(getLoggedInUser)" :name="getUserFullName(getLoggedInUser)"
                                  :image-url="getImgUrl(getLoggedInUser)" :size="8"/>

                  <span class="hidden lg:flex lg:items-center">
                    <span class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                          aria-hidden="true">{{ getUserFullName(getLoggedInUser) }}</span>
                    <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>

                  </span>
                </MenuButton>
                <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                      class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                      <a @mousedown="item.action"
                         :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                        {{ (item.name) }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main
            :style="{ top: `${(numberOfActiveBanners() || 0) * (mobileBannerHeight + 16)}px` }"
        >
          <div class="bg-white"
          >
            <slot/>
          </div>
        </main>
        <!--<FooterCustomFooter  v-if="footerVisible" custom-classes="text-gray-900 px-4 lg:px-7  right-0  left-0 lg:left-72"/>-->
        <footer v-if="footerVisible"
                class="bg-white text-gray-900 px-4 lg:px-7 shadow-inner fixed right-0  bottom-0 left-0 lg:left-72">
          <div class="flex flex-row items-center justify-between my-auto py-3">
            <div class="flex flex-row justify-center items-center text-xs">
              <p class="hidden md:inline">{{ ('Powered by') }}</p>
              <img class="h-5 w-auto ml-3 mr-1" src="~/assets/icon/app.png" alt=""/>
              <p>Aphelia.io</p>
            </div>
            <div class="flex flex-row  items-center justify-center  text-xs gap-2">
              <span class="leading-none">{{ today }}. All rights reserved.</span>
              <a class="mb-3.5" href="https://technuf.com/" target="_blank"><img class="h-6 w-auto ml-3 mr-1 mb-0"
                                                                                 src="~/assets/icon/logo-dark.svg"
                                                                                 alt=""/></a>
            </div>
          </div>
        </footer>

      </div>
    </div>
  </div>
  <Notification/>
  <!--  <NewFeedPostToast/>-->
  <!--  <SetPasswordModal/>-->
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {
  Dialog,
  DialogPanel, Disclosure, DisclosureButton, DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
  UserIcon,
  ArrowRightOnRectangleIcon
} from '@heroicons/vue/24/outline'

import {
  announcementBannerOpenEvent,
  announcementBannerCloseEvent,
  notificationDetailDialogue,
  useGlobalEvent, useGlobalEventLister, announcementPageRefreshEvent,
} from "~/composables/useEventBus";

import {
  ChevronDownIcon,
} from '@heroicons/vue/20/solid'
import {userStore} from "~/store/userStore";
import type {User} from "~/models/user";
import {AppRoutes} from "~/utils/other/AppRoutes";
import {clubStore} from "~/store/clubStore";
import {gradeStore} from "~/store/gradeStore";
import {useRoute, useRouter} from "vue-router";
import {companyStore} from "~/store/companyStore";
import {sessionStore} from "~/store/sessionStore";
import moment from "moment";
import {onMounted} from "vue";
import {storeToRefs} from "pinia";
import type {FeedPost} from "~/models/FeedPost";
import {FeedPostStatus} from "~/utils/enums/FeedPostStatus";
import {contestStore} from "~/store/contestStore";
import type {Contest} from "~/models/contest";
import type {FeedPostLike} from "~/models/feedPostLike";
import type {CompanyDetails} from "~/models/companyDetails";
import {announcementStore} from "~/store/announcementStore";
import {convertUTCTimeToLocalTimezone} from "~/utils/helpers";
import {AnnouncementStatus} from "~/utils/enums/AnnouncementStatus";


const useUserStore = userStore();
const useClubStore = clubStore();
const useGradeStore = gradeStore();
const useContestStore = contestStore()

const useCompanyStore = companyStore();
const useSessionStore = sessionStore();
const route = useRoute();
const router = useRouter();
const {addToAllPostList, addPostToList, deletePostFromList, clearContestStore} = useContestStore;
const {getLoggedInUser} = storeToRefs(useUserStore);
const {clearUserStore, getSidebarNavigations} = useUserStore
const {clearClubStore} = useClubStore
const {clearGradeStore} = useGradeStore
const {getCompanyCode, clearCompanyStore} = useCompanyStore
const {clearSessionStore} = useSessionStore
const {getCompanyId, getHasPaymentModule, getCompanyDetailsLocal} = storeToRefs(useCompanyStore)
const {getCurrentClub} = storeToRefs(useClubStore)
const {loggedInUser, isLoggedInUserAnAdminOfThisCompany, isLoggedInUserAStaffOfThisCompany} = storeToRefs(useUserStore)
const {getActivePostsOfCurrentEvent} = storeToRefs(useContestStore)
let today = moment().format('YYYY')
let websocketConnectionTry = 0;

const currentPath = ref<String>('');
const footerVisible = ref<boolean>(false);
const currentContest = ref<Contest>();
const {totalVoteCount, getTotalFeedPostCount, getFeedPostCount} = storeToRefs(useContestStore);

const useAnnouncementStore = announcementStore();
const {getAnnouncementsBySenderId, getAnnouncementByCompanyId} = useAnnouncementStore;

const bannerHeight = 45; // Adjust according to your banner height
const mobileBannerHeight = 45; // Adjust according to your banner height
const bannerSpacing = 20; // Adjust according to your spacing preference

let notificationsList = ref<any>([]);
const notificationBellLoadingState = ref<boolean>(true);
const notificationAnnouncement = ref<string>('');
const notificationDescription = ref<string>('');
const notificationStartDate = ref<string>('');
const notificationEndDate = ref<string>('');
const activeBanners = ref<any[]>([]);

useHead({
  title: "Aphelia | " + (getCompanyDetailsLocal.value?.name ?? ""),
  bodyAttrs: {class: ' h-full '},
  htmlAttrs: {class: 'h-full bg-slate-50'}
})
watch(route, value => {
  currentPath.value = value.path;
}, {deep: true, immediate: true},)
const {getTotalCountOfPostsAndLikes, addUpdateVoteCount} = useContestStore;
const navigation = [
  {
    name: 'Teams',
    icon: UserIcon,
    current: false,
    children: [
      {name: 'Engineering', href: '#'},
      {name: 'Human Resources', href: '#'},
      {name: 'Customer Success', href: '#'},
    ],
  }
]
const teams = [
  {id: 1, name: 'Payment History', href: '#', initial: 'P', current: false},
  {id: 2, name: 'Invoice History', href: '#', initial: 'I', current: false},

]
const userNavigation = [
  {name: 'My profile', action: goToMyProfilePage},
  {name: 'Sign out', action: onLogout},
]


const sidebarOpen = ref(false)

function getUserFullName(user: User) {
  return ((user.FirstName ?? '') + ' ' + (user.LastName ?? '')).trim();
}

function getUserFirstName(user: User) {
  return (user.FirstName ?? '').trim();
}

function hasImgUrl(user: User) {
  return (user.profilePicture ?? '').trim().length > 0;
}

function getImgUrl(user: User) {
  return user.profilePicture?.trim() ?? '';
}

async function goToMyProfilePage() {
  await router.push(AppRoutes.AdminMyProfile);
}

async function goToDashboardPage() {
  // await router.push(AppRoutes.ParentDashboard);
}


const isSelectedMenu = (currentPath: string, href: string) => {
  if (currentPath.startsWith('/rsvp') && (href == '/events')) {
    return true;
  } else {
    return currentPath.startsWith(href);
  }
}
const hasStatistic = (currentPath: string) => {
  if (currentPath.startsWith('/feed')) {
    return true;
  }
}

async function onLogout() {
  try {
    clearClubStore();
    clearGradeStore();
    clearSessionStore();
    clearContestStore();
    clearCompanyStore();
    clearUserStore();
    await router.push('/' + getCompanyCode).then();
  } catch (e) {
    console.log()
  }
}

const getTitle = () => {
  try {
    const currentPath = route.path;
    if (currentPath.startsWith('/feed')) {
      footerVisible.value = false;
      // return getCurrentClub.value.ClubName;
      const firstContest = getCurrentClub.value.Contests[0];
      if (firstContest) {
        currentContest.value = getCurrentClub.value.Contests[0];
        console.log(getCurrentClub.value.Contests[0])
        return getCurrentClub.value.Contests[0]?.Name;
      } else {
        return getCurrentClub.value.ClubName;
      }
    } else {
      footerVisible.value = true;
      return 'Welcome, ' + getUserFirstName(getLoggedInUser.value)
    }
  } catch (e) {
    return '';
  }
}

const hasBanner = () => {
  return activeBanners.value.length > 0;
}

const numberOfActiveBanners = () => {
  return activeBanners.value.length;
}

// Function to add a new banner
function addBanner(id: number, announcement: string, announcementDescription: string, startDate: string, endDate: string) {
  const newBanner = {
    id: id,
    announcement,
    announcementDescription,
    startDate: startDate ? convertUTCTimeToLocalTimezone(startDate) : null,
    endDate: endDate ? convertUTCTimeToLocalTimezone(endDate) : null
  };
  activeBanners.value.push(newBanner);
}

const findBannerIndex = (bannerId: string): number => {
  return activeBanners.value.findIndex(banner => banner.id === bannerId);
};

// Function to close a specific banner
function closeBanner(index) {
  if (activeBanners.value.length === 1) {
    activeBanners.value.splice(index - 1, 1);
  } else {
    activeBanners.value.splice(index, 1);
  }
}

function announcementEventListener() {
  useGlobalEventLister(announcementBannerOpenEvent(), function (data: any) {
    if (data) {
      addBanner(data.announcementId, data.announcement, data.announcementDescription, data.startDate, data.endDate)
    }
  })

  useGlobalEventLister(announcementBannerCloseEvent(), function (data: {
    bannerIndex: number,
    announcementId: number
  }) {
    if (data) {
      const bannerIndex = findBannerIndex(data.announcementId);
      if (bannerIndex !== -1) {
        closeBanner(bannerIndex);
      }
    }
  })
}

const openNotificationDetailsDialogue = (announcement: string, description: string, startDate: string, endDate: string) => {
  notificationAnnouncement.value = announcement;
  notificationDescription.value = description;
  notificationStartDate.value = startDate;
  notificationEndDate.value = endDate;
}

function notificationDetailsEventListener() {
  useGlobalEventLister(notificationDetailDialogue(), function (data: any) {
    if (Object.keys(data).length > 0) {
      openNotificationDetailsDialogue(data.announcement, data.description, data.startDate, data.endDate)
    }
  })
}

const closeExpiredBanners = () => {
  if (activeBanners.value.length > 0) {
    const currentDateTime = Number(moment().format('YYYYMMDDHHmm'));
    activeBanners.value.map(banner => {
      if (banner.endDate && Number(banner.endDate) < currentDateTime) {
        console.log("Closing Banner if expired by scheduler")
        const bannerIndex = findBannerIndex(banner.id);
        if (bannerIndex != -1) {
          closeBanner(bannerIndex);
        }
        useGlobalEvent(announcementPageRefreshEvent(), {})
      }
    })
  }
}


const connectWebSocket = () => {
  websocketConnectionTry++;
  if (websocketConnectionTry > 10) {
    return
  }
  try {
    setTimeout(() => {
      // populateLeftMenus();

      let config = useRuntimeConfig()


      let baseurlsops = `${config.public.BASE_URL_SOPS}`.replace("https://", '');
      const ws = new WebSocket(`wss://${baseurlsops}/stream`, [`${getLoggedInUser.value.SessionToken}`]);

      ws.onopen = (event: Event) => {
        console.log("Websocket opened successfully!")
        websocketConnectionTry = 1;
      }


      ws.onclose = (event) => {
        console.log("Error")
        if (ws.readyState === WebSocket.CLOSED) {
          console.log("Websocket closed successfully!")
        }
      }
      ws.onerror = (event) => {
        // Close the WebSocket connection before attempting to reconnect
        ws.close();
        // Try to reconnect only if the connection is not in the process of closing
        if (ws.readyState !== WebSocket.CLOSING) {
          connectWebSocket();
        }
      }
      ws.onmessage = (event: MessageEvent) => {
        let messageData = JSON.parse(event.data)
        if (messageData['route'] == '/addUpdatePost') {
          if (messageData['Result']) {
            let post: FeedPost = messageData['Result'] as FeedPost;
            if (post && post.contest_id == currentContest.value?.id) {
              if (post.Status == FeedPostStatus.ACTIVE) {
                addPostToList(post, true, false, false)
              } else if (post.Status == FeedPostStatus.PENDING) {
                addToAllPostList(post, true)
              } else if (post.Status == FeedPostStatus.DECLINED) {
                deletePostFromList(post.id)
              } else if (post.Status == FeedPostStatus.DELETED) {
                deletePostFromList(post.id)
              } else {
                deletePostFromList(post.id)
              }
            }
          }
        } else if (messageData['route'] == '/addUpdateContest') {
          if (messageData['Result']) {
            console.log(messageData)
          }
        } else if (messageData['route'] === '/likeFeedPost') {
          if (messageData['Result']) {
            let like = messageData['Result'] as FeedPostLike
            let feedPost = getActivePostsOfCurrentEvent.value.find((item) => item.id == like.feed_post_id)
            if (feedPost && feedPost.contest_id == currentContest.value?.id) {
              if (like.Status == FeedPostStatus.ACTIVE) addUpdateVoteCount(true, false)
              else addUpdateVoteCount(false, true)
            }
          }
        } else if (messageData['route'] == "/createText") {
          if (messageData['Result']) {
            const announcementId = messageData['Result'].id;
            const announcement = messageData['Result'].Name;
            const announcementDescription = messageData['Result'].TextMessages[0].Text;
            const status = messageData['Result'].Status;
            const isBroadcast = messageData['Result'].isBroadcast;
            const startDate = messageData['Result'].StartDate;
            const endDate = messageData['Result'].EndDate;
            const CompanyId = Number(messageData['Result'].CompanyId);
            const companyId = Number(getCompanyDetailsLocal.value.id);
            const senderId = messageData['Result']['TextMessages'][0].Sender_id;
            const loggedInUserId = getLoggedInUser.value.id;

            const bannerAlreadyExist = findBannerIndex(announcementId);
            if (status === AnnouncementStatus.PUBLISHED && CompanyId === companyId && bannerAlreadyExist === -1 && senderId === loggedInUserId) {
              useGlobalEvent(announcementBannerOpenEvent(), <any>{
                announcementId,
                announcement,
                announcementDescription,
                startDate,
                endDate
              });
              useGlobalEvent(announcementPageRefreshEvent(), {})
            }
          }
        }
      }
    }, 500 * websocketConnectionTry);
  } catch (e) {
    console.log(e)
  }
}


let intervalId = null;
onMounted(async () => {
  try {
    intervalId = setInterval(function () {
      closeExpiredBanners()
    }, 1000)
    connectWebSocket();
    announcementEventListener()
    notificationDetailsEventListener();
    if (route.path.startsWith('/feed')) {
      await getTotalCountOfPostsAndLikes(getCurrentClub.value.Contests[0].id)
    }
  } catch (e) {

  }
})

onUnmounted(() => {
  clearInterval(intervalId);
})


</script>
